import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Sidebar from "../components/sidebar";
import Pagination from "../components/pagination";
import Insight from "../components/insight/insight";

export default function Index({ data, pageContext }) {
  const insights = data.allWpInsight.edges;
  return (
    <Layout>
      {/* <Seo seo={data.wpPage.seo} /> */}

      <div className="profile__heading__wrapper mt-6">
        <h3 className="profile__heading text-blue">Insights</h3>
      </div>

      <main className="insight__main pb-10">
        <aside className="insight__aside">
          <Sidebar />
        </aside>

        <div className="insight__article">
          <div>
            {insights.map((insight, index) => (
              <Insight key={index} insight={insight} />
            ))}
          </div>

          <Pagination {...{ pageContext }} />
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWpInsight(
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          uri
          date
          title
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          seo {
            fullHead
          }
          insight {
            downloads {
              file {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`;
